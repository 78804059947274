.logo {
    width: 100%;
    height: auto;
    display: block;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .logo {
        width: 100px;
        height: 100px;
        padding-top: 20px;
        padding-left: 20px;
    }
}